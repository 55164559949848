.Content {
  background: var(--colors-chandelure);
  border-radius: var(--radii-small);
  border: 0.2rem solid var(--colors-perfectGray);
  padding: var(--space-small) var(--space-medium);
  font-family: var(--fonts-newDefault);
  font-size: 1rem;
  font-weight: var(--fontWeights-normal);
  line-height: var(--lineHeights-info);

  box-shadow: 0 0.2rem 0.4rem 0 #00000033; /** from figma */
  user-select: none;
  animation-duration: 400ms;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
  will-change: transform, opacity;

  &[data-state='delayed-open'][data-side='top'] {
    animation-name: slideDownAndFade;
  }

  &[data-state='delayed-open'][data-side='right'] {
    animation-name: slideLeftAndFade;
  }

  &[data-state='delayed-open'][data-side='bottom'] {
    animation-name: slideUpAndFade;
  }

  &[data-state='delayed-open'][data-side='left'] {
    animation-name: slideRightAndFade;
  }
}

.Arrow {
  fill: var(--colors-perfectGray);
}

@keyframes slideUpAndFade {
  from {
    opacity: 0;
    transform: translateY(0.2rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  from {
    opacity: 0;
    transform: translateX(-0.2rem);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  from {
    opacity: 0;
    transform: translateY(-0.2rem);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  from {
    opacity: 0;
    transform: translateX(0.2rem);
  }

  to {
    opacity: 1;
    transform: translateX(0);
  }
}
