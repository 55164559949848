.ComboboxItem[data-active-item] {
  background-color: rgba(249, 249, 249, 0.05);
  outline: none;
}
.ComboboxItem[data-focus-visible] {
  background-color: rgba(249, 249, 249, 0.05);
  outline: none;
}

.CancelButton:hover {
  background-color: rgba(249, 249, 249, 0.05);
  outline: none;
}
.CancelButton:focus {
  box-shadow: inset 0 0 0 0.1rem #f9f9f9;
  background-color: rgba(249, 249, 249, 0.05);
  outline: none;
}
