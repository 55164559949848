@keyframes slideDown {
  from {
    height: 0;
  }
  to {
    height: var(--radix-collapsible-content-height);
  }
}

@keyframes slideUp {
  from {
    height: var(--radix-collapsible-content-height);
  }
  to {
    height: 0;
  }
}

.CollapsibleContent[data-state='open'] {
  animation: slideDown 300ms ease-out;
}

.CollapsibleContent[data-state='closed'] {
  animation: slideUp 300ms ease-out;
}

@media (prefers-reduced-motion: reduce) {
  .CollapsibleContent[data-state='open'] {
    animation: none;
  }

  .CollapsibleContent[data-state='closed'] {
    animation: none;
  }
}
