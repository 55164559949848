.ToggleRoot[data-state='checked'] {
  background-color: #25abff;
}
.ToggleRoot[data-state='checked']:hover {
  box-shadow: 0 0 0 0.1rem rgba(249, 249, 249, 1);
}
.ToggleRoot[data-state='checked']:focus {
  box-shadow:
    0 0 0 0.2rem #1a1a1a,
    0 0 0 0.4rem rgba(249, 249, 249, 1);
}

.ToggleThumb[data-state='checked'] {
  background-color: #f9f9f9;
  transform: translateX(1.8rem);
}
