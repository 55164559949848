@font-face {
  font-family: 'Rubik';
  src: url('./rubik-regular.woff2') format('woff2'),
    url('./rubik-regular.woff') format('woff');
  font-weight: 400;
  font-style: 'normal';
}

@font-face {
  font-family: 'Rubik';
  src: url('./rubik-bold.woff2') format('woff2'),
    url('./rubik-bold.woff') format('woff');
  font-weight: 700;
  font-style: 'normal';
}

@font-face {
  font-family: 'Inter';
  src: url('./inter-regular.woff2') format('woff2'),
    url('./inter-regular.woff') format('woff');
  font-weight: 400;
  font-style: 'normal';
}

@font-face {
  font-family: 'Inter';
  src: url('./inter-bold.woff2') format('woff2'),
    url('./inter-bold.woff') format('woff');
  font-weight: 700;
  font-style: 'normal';
}

@font-face {
  font-family: 'Inter';
  src: url('./inter-black.woff2') format('woff2'),
    url('./inter-black.woff2') format('woff');
  font-weight: 900;
  font-style: 'normal';
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('./sourcesanspro-regular.woff2') format('woff2'),
    url('./sourcesanspro-regular.woff') format('woff');
  font-weight: 400;
  font-style: 'normal';
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('./sourcesanspro-bold.woff2') format('woff2'),
    url('./sourcesanspro-bold.woff') format('woff');
  font-weight: 700;
  font-style: 'normal';
}

@font-face {
  font-family: 'Source Sans Pro';
  src: url('./sourcesanspro-black.woff2') format('woff2'),
    url('./sourcesanspro-black.woff') format('woff');
  font-weight: 900;
  font-style: 'normal';
}
