/**
* Unfortunately, this is necessary to animate the modal via selectors since
* stitches doesn't have that ability.
*/
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes slideIn {
  from {
    opacity: 0;
    transform: translate(0px, 80px);
    transition:
      250ms ease-out transform,
      180ms ease opacity;
  }
  to {
    opacity: 1;
    transform: translate(0px, 0);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.AlertDialogOverlay[data-state='open'] {
  animation: fadeIn 150ms cubic-bezier(0.16, 1, 0, 1);
}

.AlertDialogContent[data-state='open'] {
  animation: slideIn 250ms ease;
}

.AlertDialogOverlay[data-state='closed'],
.AlertDialogContent[data-state='closed'] {
  animation: fadeOut 250ms cubic-bezier(0.16, 1, 0, 1);
}

@media (prefers-reduced-motion: reduce) {
  .AlertDialogOverlay[data-state='open'] {
    animation: none;
  }

  .AlertDialogContent[data-state='open'] {
    animation: none;
  }

  .AlertDialogOverlay[data-state='closed'],
  .AlertDialogContent[data-state='closed'] {
    animation: none;
  }
}
